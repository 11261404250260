.card-bg-primary {
  background-color: navy;
}

.cross-mark {
  color: red;
  font-size: 36px;
}

.check-mark {
  color: green;
  font-size: 36px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

#announcement {
  font-family: Arial, sans-serif;
  font-size: calc(1em + .5vw);
}

.neg-bottom-margin {
  margin-bottom: -40%;
}

/*# sourceMappingURL=index.49fcd879.css.map */
